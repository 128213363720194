
import { Options, Vue } from "vue-property-decorator";
import { pageListUser } from "@/api/safecheck";
@Options({})
export default class extends Vue {
  show = false;
  personName = "";
  refreshing = false;
  loading = false;
  finished = false;
  personList: any = [];
  changePersonId = "";
  changePersonName = "";
  page = {
    pageNum: 1,
    pageSize: 13,
  };
  fPopupOpen() {
    if (this.personList.length > 0) {
      this.personList = [];
      this.refreshing = true;
      this.onRefresh();
    }
  }
  onLoad() {
    setTimeout(async () => {
      if (this.refreshing) {
        this.personList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      const res: any = await pageListUser({
        projectId: this.$store.state.project.projectinfo.projectId,
        name: this.personName,
        ...this.page,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.loading = false;
      this.page.pageNum += 1;
      this.personList = [...this.personList, ...res.data.records];
      if (this.personList.length >= res.data.total) {
        this.finished = true;
      }
    }, 1000);
  }
  onSearch() {
    this.refreshing = true;
    this.onRefresh();
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  fCheckboxSelect(obj: any) {
    if (obj.isSelect) {
      this.changePersonId = obj.userId;
      this.changePersonName = obj.name;
    } else {
      this.changePersonId = "";
    }
    this.personList.forEach((item: any) => {
      if (item.userId !== obj.userId) {
        item.isSelect = false;
      }
    });
  }
  fSureBtn() {
    if (this.changePersonId === "") {
      return this.$toast.fail("请选择责任人!");
    }

    //返回选中对象的
    (this as any).$parent.setChangePerson({
      changePersonId: this.changePersonId,
      personliableName: this.changePersonName,
    });
    this.show = false;
  }
}
