import request from "@/utils/request";
export function mobilesourceCheckpageAll(params: any) {
  return request({
    url: "/project/projectDangerCheck/pageAll",
    method: "get",
    params: params,
  });
}
// 我的提交列表
export function mobilesourceCheckpageCreate(params: any) {
  return request({
    url: "/project/projectDangerCheck/pageCreate",
    method: "get",
    params: params,
  });
}
// 我的处理列表
export function mobilesourceCheckpageDeal(params: any) {
  return request({
    url: "/project/projectDangerCheck/pageDeal",
    method: "get",
    params: params,
  });
}
// 通知我的列表
export function mobilesourceCheckpageNotice(params: any) {
  return request({
    url: "/project/projectDangerCheck/pageNotice",
    method: "get",
    params: params,
  });
}
// 漏检记录
export function projectDangerCheckLogpage(params: any) {
  return request({
    url: "/project/projectDangerCheckLog/page",
    method: "get",
    params: params,
  });
}
// 详情
export function mobilesourceCheckgetById(id: number | string) {
  return request({
    url: "/project/projectDangerCheck/getById/" + id,
    method: "get",
  });
}
// 漏检详情
export function projectDangerCheckgetById(id: number | string) {
  return request({
    url: "/project/projectDangerCheckLog/getById/" + id,
    method: "get",
  });
}

/**
 * 记录安全巡检
 */
//区域选择
export function projectArealist(params: any) {
  return request({
    url: "/project/projectArea/list",
    method: "get",
    params: params,
  });
}

// 危险源类型列表
export function dangertypelist(params: any) {
  return request({
    url: "/project/danger/type/list",
    method: "get",
    params: params,
  });
}

// 危险源列表
export function dangersourcelist(params: any) {
  return request({
    url: "/project/danger/source/list",
    method: "get",
    params: params,
  });
}

// 保存
export function mobilesourceChecksave(data: any) {
  return request({
    url: "/project/projectDangerCheck/save",
    method: "post",
    data: data,
  });
}

// 参建单位列表
export function listProjectCompany(params: any) {
  return request({
    url: "/project/projectDept/listProjectCompany",
    method: "get",
    params: params,
  });
}

// 新增安全隐患的类型
export function dangerTypeSave(arg: any) {
  return request({
    url: "/project/danger/type/save",
    method: "POST",
    data: arg,
  });
}

// 新增安全隐患的位置
export function dangerSourceSave(arg: any) {
  return request({
    url: "/project/danger/source/save",
    method: "POST",
    data: arg,
  });
}

// 问题处理
export function mobilesourceDealsaveDeal(data: any) {
  return request({
    url: "/project/projectDangerHistory/saveDeal",
    method: "post",
    data: data,
  });
}

// 问题审核
export function mobilesourceDealsaveCheck(data: any) {
  return request({
    url: "/project/projectDangerHistory/saveCheck",
    method: "post",
    data: data,
  });
}

// 新增区域
export function projectAreaSave(arg: any, pid: any) {
  return request({
    url: "/project/projectArea/save",
    method: "post",
    data: arg,
    headers: { pId: pid },
  });
}
